import React, {useState} from "react";
import {getToken} from "../utilities/TokenHandler";
import {createDirectLine} from "botframework-webchat";
import {getNewToken} from "../utilities/TokenHandler";
import * as helpers from "../Helpers/index";
import {mergeStyles} from "@fluentui/react/lib/Styling";
import {Spinner} from "@fluentui/react";
import {Components} from "botframework-webchat-component";
import "./ChatComponent.css";
import CustomSendBox from "../CustomSendBox/CustomSendBox";
import Loader from "../Loader/Loader";
import LastUpdateInfo from "../LastUpdateInfo/LastUpdateInfo";
import NewChatIcon from "../NewChatIcon/NewChatIcon";

const md = require("markdown-it")({html: true, linkify: true});

const headerClass = mergeStyles({
  borderRadius: "15px 15px 0px 0px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "space-around",
  height: "var(--header-height)",
});

const CustomWebChat = ({directLine, styleOptions, store}) => {
  return (
    <Components.Composer
      directLine={directLine}
      styleOptions={styleOptions}
      store={store}
      renderMarkdown={md.render.bind(md)}
      render={md.render.bind(md)}
    >
      <Components.AccessKeySinkSurface className="key-bind-wrapper">
        <Components.BasicToaster className="toaster-wrapper" />
        <Components.BasicTranscript className="chat-space-wrapper" />
        <Components.BasicConnectivityStatus className="connectivity-wrapper" />
        <Loader />
        <CustomSendBox />
      </Components.AccessKeySinkSurface>
    </Components.Composer>
  );
};

const ChatComponent = ({sectionOptions, styleOptions}) => {
  const [tok, setToken] = useState(null);
  const [directLine, setDirectLine] = useState();
  const [store, SetStore] = useState();
  const [loading, SetLoading] = useState(true);

  useState(async () => {
    const {token} = await getToken();
    setToken(token);
    setDirectLine(
      createDirectLine({
        token,
        domain: "https://europe.directline.botframework.com/v3/directline",
      })
    );
    SetStore(helpers.createStore());

    SetLoading(false);
  }, []);

  useState(() => {
    console.log(tok);
  }, [tok]);

  const refresh = async () => {
    SetLoading(true);
    console.log("Reset Test");
    const {token} = await getNewToken();
    console.log(token);
    const newdirline = createDirectLine({
      token,
      domain: "https://europe.directline.botframework.com/v3/directline",
    });
    setDirectLine(newdirline);
    console.log(newdirline);
    SetStore(helpers.createStore());

    console.log(store);
    SetLoading(false);
  }; 

  return (
    <section style={sectionOptions}>
      <header className={headerClass}>
        {/* <img src="Iveco_Group.png" className="" alt="logo" style={{
          width: 'clamp(120px, 40vw, 120px)'
        }}/> */}
        <img src="Iveco_Group.png" className="App-Banner" alt="logo" />
        <NewChatIcon
          onClick={async () => await refresh()}
          className="new-chat"
        />
      </header>
      <div className="react-container webchat">
        {loading && (
          <Spinner
            style={{
              backgroundColor: "var(--bg-gray)",
              color: "var(--iveco-blue)",
              height: "100%",
            }}
          />
        )}
        {!!directLine && !loading && store && (
          <CustomWebChat
            directLine={directLine}
            styleOptions={styleOptions}
            store={store}
            renderMarkdown={md.render.bind(md)}
            render={md.render.bind(md)}
          />
        )}
      </div>
    </section>
  );
};

export default ChatComponent;
